import React, { useEffect } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ThinLayout } from "../Layouts/ThinLayout";
import { ResponseEdit } from "../Components/Response/Edit";
import {
  ItemType,
  ResponseSubjectType,
  Response as ResponseType,
} from "../types";
import { Publish } from "@mui/icons-material";
import { BlingCheckmark } from "../Components/BlingCheckmark";
import { useQuery, useMutation } from "@apollo/client";
import {
  CLAIM_LICENSE,
  CampaignsResult,
  GET_CAMPAIGNS,
  GET_RESPONSE,
  GET_USERS,
  ResponseResult,
  UPDATE_CAMPAIGN,
  UPDATE_RESPONSE,
  UsersResult,
} from "../Queries";
import LicenseGate from "../Components/License/Gate";

export const UserResponse = () => {
  const [searchParams] = useSearchParams();

  const key = searchParams.get("key");
  const responseKeyBlob = atob(key || "");
  const responseParts = responseKeyBlob.split(":");

  const responseId = responseParts[0];
  const responseToken = responseParts[1];

  const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, {
    // refetchQueries: ["GetCampaign"],
  });

  const [updateResponse] = useMutation(UPDATE_RESPONSE, {
    // refetchQueries: ["GetResponse"],
  });

  const [claimLicense, { loading: claimPending }] = useMutation(CLAIM_LICENSE, {
    refetchQueries: ["MyRole"],
  });

  const { loading: responseLoading, data: responseData } =
    useQuery<ResponseResult>(GET_RESPONSE, {
      variables: { pk: responseId },
      context: {
        headers: {
          Authorization: `JWT ${responseToken}`,
        },
      },
    });

  const response = responseData ? responseData.response : undefined;

  useEffect(() => {
    if (response?.submitted) {
      const audio = new Audio("/response-submitted.mp3");
      audio.play();
    }
  }, [response?.submitted]);

  const { loading: usersLoading, data: usersData } = useQuery<UsersResult>(
    GET_USERS,
    {
      variables: {
        filters: {},
      },
      context: {
        headers: {
          Authorization: `JWT ${responseToken}`,
        },
      },
    },
  );

  const users = usersData ? usersData.users : undefined;
  const author =
    users !== undefined
      ? users.find((user) => response?.authorId === user.id)
      : undefined;

  const {
    loading: campaignsLoading,
    error: campaignsError,
    data: campaignsData,
  } = useQuery<CampaignsResult>(GET_CAMPAIGNS, {
    variables: {
      filters: {},
    },
    context: {
      headers: {
        Authorization: `JWT ${responseToken}`,
      },
    },
  });

  const campaigns = campaignsData ? campaignsData.campaigns : [];

  const campaign = campaigns.find(
    (campaign) => response?.campaignId === campaign.id,
  );

  const showLoading = responseLoading || usersLoading || campaignsLoading;

  const template = response ? response.form : null;

  const subjects =
    users !== undefined
      ? users.filter(
          (subject) =>
            (
              response?.responseSubjects.map((sr) => sr.subjectUserId) || []
            ).indexOf(subject.id) >= 0,
        )
      : [];

  if (!response || !author || !campaign || !template) {
    return (
      <ThinLayout title="User Response" loading={showLoading}>
        {showLoading ? (
          <></>
        ) : (
          <>We could not find the response associated with this URL.</>
        )}
      </ThinLayout>
    );
  }

  if (response.submitted) {
    return (
      <ThinLayout title="">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
            opacity: 1,
            animation: "fade 2s linear",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4">
              You&apos;re all done! Your submission has been received.
            </Typography>
            <BlingCheckmark
              active={false}
              completed={true}
              size="large"
              divProps={{ style: { marginTop: 10 } }}
            />
          </Box>
        </Box>
      </ThinLayout>
    );
  }

  async function handleResponseChange(response: ResponseType) {
    await updateResponse({
      variables: {
        id: response.id,
        submitted: response.submitted,
        submittedDate: new Date().toISOString(),
        responseSubjects: response.responseSubjects,
      },
      context: {
        debounceKey: `response-${response.id}`,
        headers: {
          Authorization: `JWT ${responseToken}`,
        },
      },
      optimisticResponse: {
        updateResponse: {
          __typename: "Response",
          ...(response as any),
        },
      },
    });
  }

  function handleSubmit() {
    if (!response || !campaign) {
      return;
    }
    handleResponseChange({ ...response, submitted: true });
  }

  // BEGIN TEMPORARY -- SHIFT POINTS VALIDATION TO BACKEND??
  const pointsItems = template.items.filter(
    (item) => item.itemType === ItemType.Points,
  );
  const pointItemIds = pointsItems.map((pi) => pi.id);
  const userSubjects = response.responseSubjects.filter(
    (rs) => rs.subjectType === ResponseSubjectType.Peer,
  );
  const subjectPointAnswers = userSubjects
    .map((rs) =>
      rs.answers.filter((answer) => pointItemIds.includes(answer.itemId)),
    )
    .flat();
  const pointTotalDiffs = pointsItems.map(
    (pi) =>
      (pi.scale ? pi.scale * userSubjects.length : 0) -
      subjectPointAnswers
        .filter((spa) => spa.itemId === pi.id)
        .reduce((prev, ans) => prev + (ans.value || 0), 0),
  );

  const pointVariancesValidated = pointsItems.every(
    (pi) =>
      Math.max(...subjectPointAnswers.map((spa) => spa.value || 0)) -
        Math.min(...subjectPointAnswers.map((spa) => spa.value || 0)) >=
      (pi.minVariance ? pi.minVariance : 0),
  );

  const pointsMatched =
    pointTotalDiffs.filter((diff) => diff != 0).length === 0;
  const pointsValidated =
    pointsItems.length === 0 ||
    (pointsItems.length * userSubjects.length == subjectPointAnswers.length &&
      pointsMatched &&
      pointVariancesValidated);
  // END TEMPORARY

  const responsesMissing = response.responseSubjects.find(
    (sr) => !sr.completed,
  );
  const canSubmit = !responsesMissing && pointsValidated;

  return (
    <ThinLayout title={campaign.name} loading={showLoading}>
      <LicenseGate jwt={responseToken}>
        <ResponseEdit
          response={response}
          author={author}
          subjects={subjects}
          formItems={template?.items || []}
          onChange={handleResponseChange}
          instructions={template.instructions}
        />
        <Tooltip
          title={
            !canSubmit ? "All tabs must be completed before you can submit" : ""
          }
        >
          <span>
            <Button
              variant="contained"
              endIcon={<Publish />}
              onClick={handleSubmit}
              disabled={Boolean(!canSubmit)}
              sx={{ ml: 2 }}
            >
              Submit
            </Button>
          </span>
        </Tooltip>
      </LicenseGate>
    </ThinLayout>
  );
};

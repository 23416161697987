import React, { useState } from "react";
import { Button, IconButton } from "@mui/material";
import { useParams } from "react-router-dom";
import { ThinLayout } from "../Layouts/ThinLayout";
import { ResponseEdit } from "../Components/Response/Edit";
import { ItemSubjectType, ResponseSubjectType, User } from "../types";
import { Close, Publish } from "@mui/icons-material";
import randomUUID from "../UUID";
import { useQuery } from "@apollo/client";
import { GET_FORM, FormResult } from "../Queries";
import { useNavigate } from "react-router-dom";

export const PreviewTemplate = () => {
  const [submitted, setSubmitted] = useState(false);
  const { templateId } = useParams();
  const navigate = useNavigate();

  const { loading: formLoading, data: formData } = useQuery<FormResult>(
    GET_FORM,
    { variables: { pk: templateId } },
  );
  const template = formData ? formData.form : undefined;

  const showLoading = formLoading;

  if (!template) {
    return (
      <ThinLayout title="Preview Template" loading={showLoading}>
        Template {templateId} not found
      </ThinLayout>
    );
  }

  const users: User[] = [
    {
      id: randomUUID(),
      email: "",
      firstName: "Student",
      lastName: "A",
      headshot: "",
      ssoId: "",
      isLearner: true,
    },
    {
      id: randomUUID(),
      email: "",
      firstName: "Student",
      lastName: "B",
      headshot: "",
      ssoId: "",
      isLearner: true,
    },
    {
      id: randomUUID(),
      email: "",
      firstName: "Student",
      lastName: "C",
      headshot: "",
      ssoId: "",
      isLearner: true,
    },
  ];

  const author = users[0];

  const hasPeerSelfType = template.items.some(
    (item) => item.subjectType === ItemSubjectType.PeerSelf,
  );
  const hasPeerOnlyType = template.items.some(
    (item) => item.subjectType === ItemSubjectType.PeerOnly,
  );
  const hasGeneralType = template.items.some(
    (item) => item.subjectType === ItemSubjectType.General,
  );

  const userSubjects = hasPeerSelfType
    ? users.map((subject) => ({
        id: randomUUID(),
        answers: [],
        subjectType: ResponseSubjectType.Peer,
        subjectUserId: subject.id,
        completed: true,
      }))
    : hasPeerOnlyType
      ? users.map((subject) => ({
          id: randomUUID(),
          answers: [],
          subjectType: ResponseSubjectType.Peer,
          subjectUserId: subject.id,
          completed: true,
        }))
      : [];

  const generalSubjects = hasGeneralType
    ? [
        {
          id: randomUUID(),
          answers: [],
          subjectType: ResponseSubjectType.General,
          subjectUserId: null,
          completed: true,
        },
      ]
    : [];

  const response = {
    id: randomUUID(),
    campaignId: "",
    authorId: author.id,
    groupId: "",
    submitted: false,
    submittedDate: new Date(),
    hasAnswers: false,
    responseSubjects: [...userSubjects, ...generalSubjects],
    tokenizedLink: "",
  };

  if (submitted) {
    return (
      <ThinLayout title={`${template.name} [PREVIEW]`}>
        You&apos;re all done! Thanks for your feedback!
      </ThinLayout>
    );
  }

  function handleResponseChange() {
    // todo: anything?
  }

  function handleSubmit() {
    setSubmitted(true);
  }

  return (
    <ThinLayout
      title={`${template.name}`}
      loading={showLoading}
      secondaryTitle={`You are previewing ${template.name}`}
      secondaryActionComponent={
        <IconButton edge="end" aria-label="close" onClick={() => navigate(-1)}>
          <Close fontSize={"small"} />
        </IconButton>
      }
    >
      <ResponseEdit
        response={response}
        author={author}
        subjects={users}
        formItems={template.items}
        onChange={handleResponseChange}
        instructions={template.instructions}
        extraTopMargin
      />
      <Button
        sx={{ ml: 2 }}
        variant="contained"
        endIcon={<Publish />}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </ThinLayout>
  );
};

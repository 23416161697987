import { gql } from "@apollo/client";
import {
  Campaign,
  Course,
  FormItem,
  Section,
  Team,
  Template,
  Response,
  User,
  Results,
  MyResponse,
  GroupCategory,
} from "./types";

export interface SectionsResult {
  sections: Section[];
}

export const item = `
  items(order: { order: ASC }) {
    id
    category
    itemType
    label
    optional
    minVariance
    options {
      id
      label
      order
      value
    }
    order
    recipientType
    scale
    subjectType
    visibility
  }
`;

export const GET_SECTIONS = gql`
  query GetSections($filters: SectionFilter) {
    sections(filters: $filters) {
      id
      name
      course {
        id
        name
      }
      userIds
    }
  }
`;

export interface GroupsResult {
  groups: Team[];
}

export const GET_GROUPS = gql`
  query GetGroups($filters: GroupFilter) {
    groups(filters: $filters, order: { name: ASC }) {
      id
      leaderId
      name
      userIds
    }
  }
`;

export const ADD_GROUP = gql`
  mutation AddGroup(
    $name: String!
    $categoryId: String
    $courseId: String
    $userIds: [String!]
  ) {
    addGroup(
      name: $name
      categoryId: $categoryId
      courseId: $courseId
      userIds: $userIds
    ) {
      ... on Group {
        id
        leaderId
        name
        userIds
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup($id: String!, $name: String, $userIds: [String!]) {
    updateGroup(id: $id, name: $name, userIds: $userIds) {
      ... on Group {
        id
        leaderId
        name
        userIds
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const SET_GROUP_LEADER = gql`
  mutation SetGroupLeader($id: String!, $leaderId: String) {
    setGroupLeader(id: $id, leaderId: $leaderId) {
      ... on Group {
        id
        leaderId
        name
        userIds
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation DeleteGroup($id: String!) {
    deleteGroup(id: $id)
  }
`;

export interface UsersResult {
  users: User[];
}

export const GET_USERS = gql`
  query GetUsers($filters: UserFilter) {
    users(filters: $filters) {
      id
      email
      headshot
      firstName
      lastName
      ssoId
      isLearner
    }
  }
`;

export interface FormsResult {
  forms: Template[];
}

export const GET_FORMS = gql`
  query GetForms($filters: FormFilter) {
    forms(filters: $filters) {
      id
      active
      instructions
      modified
      name
      ${item}
      institutionCreated
      isDirectShare
      isGlobal
      isShared
      isSnapshot
      isMine
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export interface FormResult {
  form: Template;
}

export const GET_FORM = gql`
  query GetForm($pk: ID!) {
    form(pk: $pk) {
      id
      active
      instructions
      modified
      name
      ${item}
      institutionCreated
      isDirectShare
      isGlobal
      isShared
      isSnapshot
      isMine
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export interface AddFormResult {
  addForm: Template;
}

export const ADD_FORM = gql`
  mutation AddForm($name: String!) {
    addForm(name: $name) {
      ... on Form {
        id
        active
        instructions
        modified
        name
        ${item}
        institutionCreated
        isDirectShare
        isGlobal
        isShared
        isSnapshot
        isMine
        user {
          id
          email
          firstName
          lastName
        }
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export interface CloneFormResult {
  cloneForm: Template;
}

export const CLONE_FORM = gql`
  mutation CloneForm($id: String!) {
    cloneForm(id: $id) {
      ... on Form {
        id
        active
        instructions
        modified
        name
        ${item}
        institutionCreated
        isDirectShare
        isGlobal
        isShared
        isSnapshot
        isMine
        user {
          id
          email
          firstName
          lastName
        }
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const DELETE_FORM = gql`
  mutation DeleteForm($id: String!) {
    deleteForm(id: $id)
  }
`;

export interface ShareFormResult {
  shareForm: Template;
}

export const SHARE_FORM = gql`
  mutation ShareForm($id: String!, $share: Boolean!) {
    shareForm(id: $id, share: $share) {
      ... on Form {
        id
        active
        instructions
        modified
        name
        ${item}
        institutionCreated
        isDirectShare
        isGlobal
        isShared
        isSnapshot
        isMine
        user {
          id
          email
          firstName
          lastName
        }
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export interface UpdateFormResult {
  updateForm: Template;
}

export const UPDATE_FORM = gql`
  mutation UpdateForm(
    $id: String!
    $instructions: String
    $name: String
    $items: [FormItemInput!]
  ) {
    updateForm(
      id: $id
      instructions: $instructions
      name: $name
      items: $items
    ) {
      ... on Form {
        id
        active
        instructions
        modified
        name
        ${item}
        institutionCreated
        isDirectShare
        isGlobal
        isShared
        isSnapshot
        isMine
        user {
          id
          email
          firstName
          lastName
        }
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export interface FormItemsResult {
  formItems: FormItem[];
}

export const GET_FORMITEMS = gql`
  query GetFormItems($filters: FormItemFilter) {
    formItems(filters: $filters) {
      id
      category
      itemType
      label
      optional
      minVariance
      options {
        id
        label
        order
        value
      }
      order
      recipientType
      scale
      subjectType
      visibility
    }
  }
`;

export interface CampaignsResult {
  campaigns: Campaign[];
}

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($filters: CampaignFilter) {
    campaigns(filters: $filters) {
      id
      activity {
        id
        created
        label
      }
      assignmentEnabled
      assignmentGradingMode
      assignmentPoints
      assignmentRatingProportion
      course {
        id
        name
      }
      dueDate
      formId
      form {
        id
        name
      }
      groupcategory {
        id
        name
      }
      groups(order: { name: ASC }) {
        id
        leaderId
        name
        userIds
      }
      name
      released
      sectionId
      state
      submittedResponses
      totalResponses
    }
  }
`;

export const ADD_CAMPAIGN = gql`
  mutation AddCampaign($formId: String, $name: String!, $sectionId: String, $dueDate: String) {
    addCampaign(formId: $formId, name: $name, sectionId: $sectionId, dueDate: $dueDate) {
      ... on Campaign {
        id
        activity {
          id
          created
          label
        }
        assignmentEnabled
        assignmentGradingMode
        assignmentPoints
        assignmentRatingProportion
        dueDate
        formId
        form {
          id
          active
          instructions
          modified
          name
          ${item}
          institutionCreated
          isDirectShare
          isGlobal
          isShared
          isSnapshot
          isMine
        }
        groupcategory {
          id
          name
        }
        groups(order: { name: ASC }) {
          id
          leaderId
          name
          userIds
        }
        name
        released
        sectionId
        state
        submittedResponses
        totalResponses
        gradesSyncedToLms
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $id: String!
    $assignmentEnabled: Boolean
    $assignmentGradingMode: String
    $assignmentPoints: String
    $assignmentRatingProportion: Int
    $autoCloseDate: DateTime
    $autoReleaseDate: DateTime
    $autoSendDate: DateTime
    $dueDate: DateTime
    $formId: String
    $name: String
    $released: DateTime
    $state: String
    $activityLabel: String
  ) {
    updateCampaign(
      id: $id
      assignmentEnabled: $assignmentEnabled
      assignmentGradingMode: $assignmentGradingMode
      assignmentPoints: $assignmentPoints
      assignmentRatingProportion: $assignmentRatingProportion
      autoCloseDate: $autoCloseDate
      autoReleaseDate: $autoReleaseDate
      autoSendDate: $autoSendDate
      dueDate: $dueDate
      formId: $formId
      name: $name
      released: $released
      state: $state
      activityLabel: $activityLabel
    ) {
      ... on Campaign {
        id
        activity {
          id
          created
          label
        }
        assignmentEnabled
        assignmentGradingMode
        assignmentPoints
        assignmentRatingProportion
        autoCloseDate
        autoReleaseDate
        autoSendDate
        dueDate
        formId
        form {
          id
          active
          instructions
          modified
          name
          ${item}
          institutionCreated
          isDirectShare
          isGlobal
          isShared
          isSnapshot
          isMine
        }
        groupcategory {
          id
          name
        }
        groups(order: { name: ASC }) {
          id
          leaderId
          name
          userIds
        }
        name
        released
        sectionId
        state
        submittedResponses
        totalResponses
        gradesSyncedToLms
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const UPDATE_ASSIGNMENT_POINTS = gql`
  mutation UpdateAssignmentPoints($id: String!, $assignmentPoints: String!) {
    updateAssignmentPoints(id: $id, assignmentPoints: $assignmentPoints) {
      ... on Campaign {
        id
        activity {
          id
          created
          label
        }
        assignmentEnabled
        assignmentGradingMode
        assignmentPoints
        assignmentRatingProportion
        dueDate
        formId
        form {
          id
          active
          instructions
          modified
          name
          ${item}
          institutionCreated
          isDirectShare
          isGlobal
          isShared
          isSnapshot
          isMine
        }
        groupcategory {
          id
          name
        }
        groups(order: { name: ASC }) {
          id
          leaderId
          name
          userIds
        }
        name
        released
        sectionId
        state
        submittedResponses
        totalResponses
        gradesSyncedToLms
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const SEND_CAMPAIGN_REMINDERS = gql`
  mutation SendCampaignReminders($id: String!) {
    sendCampaignReminders(id: $id)
  }
`;

export const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($id: String!) {
    deleteCampaign(id: $id)
  }
`;

export interface CampaignResult {
  campaign: Campaign;
}

export const GET_CAMPAIGN = gql`
  query GetCampaign($pk: ID!) {
    campaign(pk: $pk) {
      id
      activity {
        id
        created
        label
      }
      assignmentEnabled
      assignmentGradingMode
      assignmentPoints
      assignmentRatingProportion
      autoCloseDate
      autoReleaseDate
      autoSendDate
      course {
        id
        name
      }
      dueDate
      formId
      form {
        id
        active
        instructions
        modified
        name
        isSnapshot
        ${item}
        institutionCreated
        isDirectShare
        isGlobal
        isShared
        isSnapshot
        isMine
      }
      groupcategory {
        id
        name
      }
      groups(order: { name: ASC }) {
        id
        leaderId
        name
        userIds
      }
      name
      released
      sectionId
      state
      submittedResponses
      totalResponses
      gradesSyncedToLms
    }
  }
`;

export interface ResponsesResult {
  responses: Response[];
}

export const GET_RESPONSES = gql`
  query GetResponses($filters: ResponseFilter) {
    responses(filters: $filters) {
      id
      authorId
      campaignId
      groupId
      submitted
      submittedDate
      responseSubjects {
        id
        answers {
          id
          itemId
          modified
          text
          value
        }
        subjectType
        subjectUserId
        completed
      }
      tokenizedLink
    }
  }
`;

export const GET_RESPONSES_SUMMARY = gql`
  query GetResponsesSummary($filters: ResponseFilter) {
    responses(filters: $filters) {
      id
      authorId
      campaignId
      groupId
      submitted
      submittedDate
      tokenizedLink
      campaignDueDate
      hasAnswers
    }
  }
`;

export interface MyResponsesResult {
  responses: MyResponse[];
}

export const GET_MY_RESPONSES = gql`
  query GetMyResponses($filters: ResponseFilter) {
    responses(filters: $filters) {
      id
      authorId
      campaignId
      campaignName
      campaignDueDate
      campaignState
      campaignReleased
      campaignResultsLink
      groupId
      submitted
      tokenizedLink
    }
  }
`;

export interface ResponseResult {
  response: Response;
}

export const GET_RESPONSE = gql`
  query GetResponse($pk: ID!) {
    response(pk: $pk) {
      id
      authorId
      campaignId
      groupId
      form {
        id
        active
        instructions
        modified
        name
        ${item}
        userId
      }
      submitted
      submittedDate
      responseSubjects {
        id
        answers {
          id
          itemId
          modified
          text
          value
        }
        subjectType
        subjectUserId
        completed
      }
      tokenizedLink
    }
  }
`;

export const UPDATE_RESPONSE = gql`
  mutation UpdateResponse(
    $id: String!
    $submitted: Boolean
    $submittedDate: DateTime
    $responseSubjects: [ResponseSubjectInput!]
  ) {
    updateResponse(
      id: $id
      submitted: $submitted
      submittedDate: $submittedDate
      responseSubjects: $responseSubjects
    ) {
      ... on Response {
        id
        authorId
        campaignId
        groupId
        submitted
        submittedDate
        responseSubjects {
          id
          answers {
            id
            itemId
            modified
            text
            value
          }
          subjectType
          subjectUserId
          completed
        }
        tokenizedLink
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export interface ResultsResult {
  results: Results;
}

export const GET_RESULTS = gql`
  query Results($id: String!, $userId: String) {
    results(id: $id, userId: $userId) {
      id
      analyses {
        question {
          id
          category
          itemType
          label
        }
        self
        others
        gap
        user {
          id
          email
          firstName
          lastName
        }
        label
        id
        outlier
      }
      aggregates {
        comments {
          id
          comment
          respondent {
            id
            email
            firstName
            lastName
          }
          recipient {
            id
            email
            firstName
            lastName
          }
        }
        question {
          id
          category
          itemType
          label
        }
        id
      }
      overall {
        self
        given
        received
      }
      campaign {
        dueDate
        formId
        groupcategory {
          id
          name
        }
        groups(order: { name: ASC }) {
          id
          leaderId
          name
          userIds
        }
        name
        released
        sectionId
        state
        gradesSyncedToLms
      }
      form {
        id
        active
        instructions
        modified
        name
        ${item}
        userId
      }
      user {
        id
        email
        headshot
        firstName
        lastName
        ssoId
      }
    }
  }
`;

export interface CourseResult {
  course: Course;
}

export const GET_COURSE = gql`
  query GetCourse($pk: String) {
    course(pk: $pk) {
      id
      name
      fullSyncCapable
      fullSyncReady
      fullSyncEnabled
      lmsType
      oauthUrl
    }
  }
`;

export const OBTAIN_LMS_TOKEN = gql`
  mutation ObtainLmsToken($state: String!, $code: String!) {
    obtainLmsToken(state: $state, code: $code)
  }
`;

export const SET_COURSE_MODE = gql`
  mutation SetCourseMode($pk: String, $fullSync: Boolean!) {
    setCourseMode(pk: $pk, fullSync: $fullSync) {
      ... on Course {
        id
        name
        fullSyncCapable
        fullSyncReady
        fullSyncEnabled
        lmsType
        oauthUrl
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const SYNC_COURSE = gql`
  mutation SyncCourse($pk: String) {
    syncCourse(pk: $pk) {
      ... on Course {
        id
        name
        fullSyncCapable
        fullSyncReady
        fullSyncEnabled
        lmsType
        oauthUrl
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export interface RoleResult {
  myRole: string;
}

export const GET_ROLE = gql`
  query MyRole {
    myRole
  }
`;

export const EXCLUDE_GROUP = gql`
  mutation ExcludeGroup($campaignId: String!, $groupId: String!) {
    excludeGroup(campaignId: $campaignId, groupId: $groupId)
  }
`;

export interface GroupCategoriesResult {
  groupCategories: GroupCategory[];
}

export const GET_GROUP_CATEGORIES = gql`
  query GetGroupCategories($filters: GroupCategoryFilter) {
    groupCategories(filters: $filters) {
      id
      name
      course {
        id
        name
      }
    }
  }
`;

export const SET_CAMPAIGN_GROUPCATEGORY = gql`
  mutation SetCampaignGroupcategory($id: String!, $groupcategoryId: String) {
    setCampaignGroupcategory(id: $id, groupcategoryId: $groupcategoryId) {
      ... on Campaign {
        id
        activity {
          id
          created
          label
        }
        assignmentEnabled
        assignmentGradingMode
        assignmentPoints
        dueDate
        formId
        form {
          id
          active
          instructions
          modified
          name
          ${item}
          institutionCreated
          isDirectShare
          isGlobal
          isShared
          isSnapshot
          isMine
        }
        groupcategory {
          id
          name
        }
        groups(order: { name: ASC }) {
          id
          leaderId
          name
          userIds
        }
        name
        released
        sectionId
        state
        submittedResponses
        totalResponses
        gradesSyncedToLms
      }
      ... on OperationInfo {
        messages {
          kind
          message
          field
        }
      }
    }
  }
`;

export const CLAIM_LICENSE = gql`
  mutation ClaimLicense($tokenString: String!) {
    claimLicense(tokenString: $tokenString) {
      ... on LicenseToken {
        id
      }
    }
  }
`;

export const GET_LICENSE = gql`
  query MyLicense {
    myLicense
  }
`;

export interface LicenseResult {
  myLicense: string;
}

export const PUSH_CAMPAIGN_GRADES = gql`
  mutation PushCampaignGrades($id: String!) {
    pushCampaignGrades(id: $id)
  }
`;
